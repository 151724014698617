/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 11/13/2020
 * @Example
 */
import React                                   from "react";
import {FormGroup, Col, Row}            from "react-bootstrap";
import Config                                  from "../../../../config";
import ActionToolbar                           from "../../../common/toolbar/action-toolbar";
import Approvals                               from "../../../approvals/approvals";
import LabelText                               from "../../../common/label-text/label-text";
import {bindActionCreators, compose}           from "redux";
import {connect}                               from "react-redux";
import { Input, withStyles} from "@material-ui/core";
import * as ApprovalActions                    from "../../../../redux/approvals/approvals_actions";
import * as generalActions                     from "../../../../redux/general/general_actions";
import Filter                from "../../../filter/filter";
import moment                from "moment";
import {Button, DatePicker, Dropdown, Label, Avatar, Typography, TextInput } from "diginet-core-ui/components";
import W29F2038                       from "../W29F2038/W29F2038";
const styles = {
    inputTime: {
        '& .DGN-UI-TextInput': {
            '& .DGN-UI-InputBase': {
                '& input': {
                    textAlign: "center",
                }
            }
        }
    },
    bgActive : {
        '& .DGN-UI-TextInput': {
            '& .DGN-UI-InputBase': {
                backgroundColor: `${Config.coreTheme?.colors?.warning || "#FFAA00"} !important`,
            }
        }
    },
    bgDisable : {
        '& .DGN-UI-TextInput': {
            '& .DGN-UI-InputBase': {
                backgroundColor: `${Config.coreTheme?.colors?.dark12 || "#ECECEE"} !important`,
            }
        }
    },
};
const numberInputTime = 5;
class W29F2035 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm   : {},
            isExpanded : false,
            VoucherID  : "",
            AppStatusID: "",
            Employee: "",
            DateFrom: null,
            DateTo: null,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            isShowW29f2038: false,
        };
        this.filterCboEmployees  = {
            strSearch: "",
            limit    : 50,
            skip     : 0
        };
        this.listNumberInputTime = [];
        for (let i = 1; i <= numberInputTime; i++) {
            this.listNumberInputTime.push(i);
        }
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W29F2035", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W29F2035",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({cboEmployeeLoading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({cboEmployeeLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W29F2035",
            Language: Config.language || 84
        };
        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return false;
        this.loadCboEmployees();
        this.loadCboAppStatus();
        this.checkNotify();
    };

    componentWillReceiveProps = () => {
        this.checkNotify();
    };

    checkNotify = () => {
        const {VoucherID} = this.getInfo();
        if(VoucherID && !this.voucher_id){
            this.voucher_id = VoucherID;
            this.loadFormInfo(VoucherID,(data)=>{
                this.voucher_id=null;
                data.VoucherID=VoucherID;
                this.refs['Approvals'].showPopup(data);
            });
        }
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('voucher_id')) {
            return {VoucherID: url.get('voucher_id')};
        } else if (location && location.state) {
            return {
                VoucherID: location.state.voucher_id,
            }
        } else {
            return {};
        }
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W29F2035",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({
            dataForm: {}
        });
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    renderFormInfo = (data) => {
        const {classes} = this.props;
        if (data && data.UserPictureURL && data.UserPictureURL.indexOf('http') < 0) {
            data.UserPictureURL = Config.getCDNPath() + data.UserPictureURL;
        }
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("Nhan_vien")}
                                value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"}
                                             style={{width: "100%", height: 37}}>
                                                {data?.UserPictureURL ? (
                                                    <Avatar
                                                        className={"mgr10"}
                                                        readOnly={true}
                                                        width={32}
                                                        height={32}
                                                        src={data.UserPictureURL}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        readOnly={true}
                                                        width={32}
                                                        height={32}
                                                        src={require("../../../../assets/images/general/user_default.svg")}
                                                    />
                                                )}
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={Config.helpers.getObjectValue(data, "EmployeeName", "") + " - " + Config.helpers.getObjectValue(data, "EmployeeID", "")}
                                                disableUnderline={true}
                                            />
                                        </div>
                                    );
                                }}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        {data.AttendanceDate && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Typography style={{margin: "42px 0 12px 0", color: `${Config.coreTheme.colors.primary}`}} type={"h1"}>
                                    {Config.lang("Ngay")} {moment(data.AttendanceDate).format("DD/MM/YYYY") || ""} {(data.AttendanceType && `- ${data.AttendanceType}`) || ""}
                                </Typography>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Typography style={{marginBottom: 29, color: `${Config.coreTheme.colors.primary}`}} type={"h1"}> {data.ShiftName || ""}</Typography>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                                    {Object.keys(data).length > 0 && this.listNumberInputTime && this.listNumberInputTime.map((num) => {
                                        if (data["TimeOn" + num] === "" || data["TimeOff" + num] === "") return null;
                                        return (
                                            <Row key={num}>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <div
                                                        className={classes.inputTime + " " + (data["InUpdate" + num] ? classes.bgActive : classes.bgDisable)}
                                                    >
                                                        <TextInput viewType={"outlined"}
                                                                   value={data["TimeOn" + num]}
                                                                   disabled={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <div
                                                        className={classes.inputTime + " " + (data["OutUpdate" + num] ? classes.bgActive : classes.bgDisable)}
                                                    >
                                                        <TextInput viewType={"outlined"}
                                                                   value={data["TimeOff" + num]}
                                                                   disabled={true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Col>
                    </Row>
                </FormGroup>
                <FormGroup style={{ marginBottom: "7px" }}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("Ly_do")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "Reason", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        )
    };

    handleFilterChange = (key,e) => {
        if(!key) return false;
        const {value} = e;
        switch (key) {
            case "AppStatusID":
                this.setState({AppStatusID: value});
                break;
            case "EmployeeID":
                this.setState({Employee: value});
                break;
            case "DateFrom":
                this.setState({DateFrom: value});
                break;
            case "DateTo":
                this.setState({DateTo: value});
                break;
            default:
                break;

        }
    };

    loadGrid = () => {
        if(this.refs['Approvals']) this.refs['Approvals'].onReset(true);
    };

    filterRender = () => {
        const {getCboAppStatus} = this.props;
        const {dataCboEmployees, cboEmployeeLoading} = this.state;
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                isUseDDCore
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            label={Config.lang("Trang_thai_duyet")}
                                            viewType={"outlined"}
                                            valueExpr={"AppStatusID"}
                                            displayExpr={"AppStatusName"}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => this.handleFilterChange("AppStatusID", e)}
                                            dataSource={getCboAppStatus}
                                            value={this.state.AppStatusID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataCboEmployees?.rows || []}
                                            total={dataCboEmployees?.total ?? 0}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            value={this.state.Employee}
                                            loading={cboEmployeeLoading}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            renderSelectedItem={"EmployeeName"}
                                            searchDelayTime={1000}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            onChange={(e) => this.handleFilterChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Label className={"mgr5"}>{Config.lang("Ngay_bo_sung")}</Label>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5 full_w"}>{Config.lang("Tu")}</Label>
                                            <DatePicker
                                                actionIconAt={'end'}
                                                max={this.state.DateTo}
                                                placeholder={'DD/MM/YYYY'}
                                                displayFormat={'DD/MM/YYYY'}
                                                value={this.state.DateFrom}
                                                viewType={'outlined'}
                                                onChange={e => this.handleFilterChange("DateFrom", e)}
                                                clearAble
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5 full_w"}>{Config.lang("Den")}</Label>
                                            <DatePicker
                                                actionIconAt={'end'}
                                                min={this.state.DateFrom}
                                                placeholder={'DD/MM/YYYY'}
                                                displayFormat={'DD/MM/YYYY'}
                                                value={this.state.DateTo}
                                                viewType={'outlined'}
                                                onChange={e => this.handleFilterChange("DateTo", e)}
                                                clearAble
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <Button text={Config.lang('Tim_kiem')}
                                        startIcon={'search'}
                                        color={"primary"}
                                        viewType={"outlined"}
                                        size={"medium"}
                                        onClick={this.loadGrid}/>
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    showPopup = () => {
        this.setState({isShowW29f2038: true});
    };

    closePopup = (isLoaded = false) => {
        if(isLoaded) this.loadGrid();
        this.setState({isShowW29f2038: false});
    };

    render() {
        const {iPermission, dataForm, VoucherID,Employee, DateFrom, DateTo, AppStatusID, isShowW29f2038 } = this.state;
        if (!iPermission) return null;
        const objSearch = {UserID: Config.profile.UserID ,ApprovalStatus : AppStatusID, DateFrom:
              DateFrom, DateTo: DateTo, EmployeeID: Employee || "",
              DepartmentID: "", ProjectID:"", DateFrom2: null, DateTo2: null, IsRegisterType : 0};
        return (
            <React.Fragment>
                { isShowW29f2038 ?
                    <W29F2038 open={isShowW29f2038} iPermission={iPermission} closePopup={this.closePopup}/> : ""}
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Duyet_bo_sung_thoi_gian_vao_ra")}
                    >
                        <Button
                            disabled={iPermission <= 1}
                            viewType="filled"
                            color="primary"
                            text={Config.lang("Duyet_hang_loat")}
                            onClick={this.showPopup}
                        />
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                allowHistoryApproval
                                FormID={"W29F2035"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                disabledApproval={iPermission < 2}
                                singleClick
                                dataFilter={objSearch}
                                filterRender={this.filterRender}
                                formInfoRender={this.renderFormInfo}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

}

export default compose(connect(state => ({
        getCboAppStatus: state.general.getCboAppStatus,
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W29F2035);
