import React from "react";
import {bindActionCreators, compose} from "redux";
import {withStyles, FormControl, Input, InputLabel} from "@material-ui/core";
import PropTypes            from 'prop-types';
import ButtonGeneral        from "../../../common/button/button-general";
import Config               from "../../../../config";
import {connect}            from "react-redux";
import PopoverAction        from "../../../grid-container/popover-action";
import _                    from "lodash";
import * as W29F2040Actions from "../../../../redux/W2X/W29F2040/W29F2040_actions";
import {MForm}              from "../../../common/form-material";
import FormHelperText       from "@material-ui/core/FormHelperText";
import moment               from "moment";
const styles = theme => {
    return {
        card: {
            width: "590px",
            height: "178px",
            padding: "37px 28px 0px 28px",
            [theme.breakpoints.down('xs')]: {
                width: "300px",
                height: "170px",
            },
        },
        input: {
            padding: '0px 0px 21px 21px'
        },
        underline: {
            paddingTop: '10px'
        },
        shrink: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    }
};


class PopoverW29F2040 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            data: [],
            errorNote: ""
        };
        this.note = (props.data && props.data.LeaveCancelReason) || "";
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({data: this.props.data});
        }
    }

    onSave = async () => {
        const {funcLoadGrid, setLoading} = this.props;
        const {data, notes} = this.state;
        const validate = MForm.isRequired(notes, "errorNote");
        if (validate && validate.errorNote) {
            this.setState(validate);
            return false;
        }
        const param = {
            FormID: "W29F2040",
            TransID: _.get(data, "TransID", ""),
            DivisionID: Config.getDivisionID(),
            EmployeeID: _.get(data, "EmployeeID", ""),
            Quantity: _.get(data, "Quantity", ""),
            LeaveDateFrom: moment(_.get(data, "LeaveDateFrom", moment()), "DD-MM-YYYY").format("YYYY-MM-DD"),
            LeaveDateTo: moment(_.get(data, "LeaveDateTo", moment()), "DD-MM-YYYY").format("YYYY-MM-DD"),
            Language: Config.language || "84",
            LeaveTypeID: _.get(data, "LeaveTypeID", ""),
            FistAbsDayQuan: _.get(data, "FirstAbsDayQuan", ""),
            LastAbsDayQuan: _.get(data, "LastAbsDayQuan", ""),
            FirstHoursLeave: _.get(data, "FirstHousrLeave", null),
            LastHoursLeave: _.get(data, "LastHoursLeave", null),
            IsRegisterType: 1,
            ReasonCancel: notes,
            arrAttachment: JSON.stringify([]),
        };
        setLoading(true);
        await this.props.w29F2040Actions.saveLeave(param, async (error, data) => {
            setLoading(false);
            if (error) {
                let message = Config.lang("DHR_Loi_chua_xac_dinh");
                switch (error.code) {
                    case "F2000E002":
                        message = "Mode " + Config.lang("DHR_Bat_buoc");
                        break;
                    case "F2000E003":
                        message = "LeaveTypeID " + Config.lang("DHR_Bat_buoc");
                        break;
                    case "F2000E004":
                        message = "IsRegisterType " + Config.lang("DHR_Bat_buoc");
                        break;
                    case "F2000E001":
                        message = Config.lang("DHR_Huy_phep_khong_thanh_cong");
                        break;
                    case "SYS013":
                        message = Config.lang("DHR_Dinh_dang_ngay_khong_dung");
                        break;
                    default:
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                if (data && data.Status === 1) {
                    Config.popup.show('INFO', data.Message);
                    return false;
                } else {
                    funcLoadGrid && funcLoadGrid();
                    Config.notify.show('success', Config.lang("DHR_Huy_phep_thanh_cong"), 2000);
                }
            }
            setLoading(false);
        });
        if (this.popover) this.popover.instance.hide();
    };

    onOpen = (target, data) => {
        const {anchorEl} = this.props;
        if (this.popover) {
            target = target ? target : anchorEl;
            this.setState({
                data: data
            });
            this.popover.instance.show(target);
        }
    };

    onClose = () => {
        if (this.popover) this.popover.instance.hide();
    };

    onNoteChange = (e) => {
        this.setState({
            notes: e.target.value
        })
    };

    render() {
        const { type, classes} = this.props;
        const {notes, errorNote} = this.state;

        return (
            <React.Fragment>
                <PopoverAction
                    reference={ref => this.popover = ref}
                    position={"right"}
                    deferRendering={false}
                    shading={false}
                    style={{zIndex: 1300}}
                    onHiding={() => this.setState({notes: "", errorNote: "" })}
                >
                    <div className={classes.card}>
                        <FormControl fullWidth={true} error={!!errorNote && !notes} style={{padding: "0px 0px 21px 0px"}}>
                            <InputLabel
                                color={type === "check" ? "primary" : "secondary"}
                                shrink={true}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 200,
                                }}
                                classes={{shrink: classes.shrink}}
                            >
                                {Config.lang("DHR_Ly_do_huy_phep")}
                            </InputLabel>
                            <Input fullWidth={true} classes={{underline: classes.underline}}
                                   color={type === "check" ? "primary" : "secondary"}
                                   value={notes}
                                   onChange={this.onNoteChange}
                            >
                            </Input>
                            <FormHelperText>{!notes && errorNote ? errorNote : ""}</FormHelperText>
                        </FormControl>
                        <ButtonGeneral
                            name={Config.lang("DHR_Huy_phep")}
                            color={"danger"}
                            variant={"text"}
                            className="pull-right"
                            style={{textTransform: 'uppercase'}}
                            size={"large"}
                            onClick={()=>this.onSave()}
                        />
                    </div>
                </PopoverAction>
            </React.Fragment>
        );
    }

}

PopoverW29F2040.propTypes = {
    className: PropTypes.string,
    anchorEl: PropTypes.any,
    type: PropTypes.string,
    data: PropTypes.any,
};

export default compose(connect(null,
    (dispatch) => ({
        w29F2040Actions: bindActionCreators(W29F2040Actions, dispatch)
    }), null, {forwardRef: true}
), withStyles(styles, {withTheme: true}))(PopoverW29F2040);