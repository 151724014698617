/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/6/2022
 * @Example
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import Config from '../../../../config';
import { useStyles } from './styles';
import { Row, Col } from 'react-bootstrap';
import GridContainer from '../../../grid-container/grid-container';
import { Column } from 'devextreme-react/data-grid';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import * as W29F2038Actions from '../../../../redux/W2X/W29F2038/W29F2038_actions';
import * as GeneralActions from '../../../../redux/general/general_actions';
import { useDispatch } from 'react-redux';
import { LoadPanel } from 'devextreme-react';
import {
    Button, ButtonIcon, Checkbox, Modal,
    ModalBody, ModalHeader, TextInput, Typography, Dropdown, TreeView, Label, DateRangePicker
} from 'diginet-core-ui/components';
const W29F2038 = (props) => {
    const { open, closePopup } = props;
    const DateFrom = null;
    const DateTo = null;
    const [minimum, setMinimum] = useState(false);
    const [isShowSelected, setIsShowSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataGrid, setDataGrid] = useState(() => ({ rows: [], total: 0 }));
    const [dataOrgChart, setDataOrgChart] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filterGrid, setFilterGrid] = useState({
        Employee: '',
        OrgchartID: [],
    });
    const [rangeDate, setRangeDate] = useState([DateFrom, DateTo]);

    const classes = useStyles({
        minimum,
    });
    const filter = useRef({skip: 0, limit: 20});
    const dispatch = useDispatch();
    const gridRef = useRef(null);
    const selectedRowDatas = useRef(null);
    const dataSource = useRef(null);
    const changePage = useRef(false);
    const changeShow = useRef(false);
    const tmpCurrentSelectedRowKeys = useRef([]);

    const loadData = () => {
        const params = {
            FormID: 'W29F2038',
            Language: Config?.language ?? '84',
            Employee: filterGrid?.Employee ?? "",
            DateFrom: rangeDate?.[0] || null,
            DateTo: rangeDate?.[1] || null,
            OrgchartID: filterGrid?.OrgChartID?.length ? JSON.stringify(filterGrid?.OrgChartID) : JSON.stringify([]),
            ...filter.current,
        };
        setLoading(true);
        dispatch(
            W29F2038Actions.getData(params, (err, data) => {
                setLoading(false);
                if (err) {
                    return Config.popup.show('ERROR', err);
                }
                if (data) {
                    dataSource.current = data;
                    if (!isShowSelected) {
                        setDataGrid(data);
                    }
                }
            })
        );
    };

    const loadDataOrgChart = () => {
        dispatch(
            GeneralActions.getOrgCharts({}, (err, data) => {
                if (err) {
                    Config.popup.show("ERROR", err);
                    return false;
                }
                if (data) {
                    setDataOrgChart(data);
                }
            })
        );
    };


    useEffect(() => {
        loadData();
        loadDataOrgChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const search = () => {
        changePage.current = true;
        filter.current.skip = 0;
        loadData();
    };

    const collapsePanel = (flag = false) => {
        setMinimum(flag);
        setTimeout(() => {
            gridRef.current.instance.resize();
        }, 500); // reSize lại lưới sau 5s theo transition
    };

    const changeTypeShow = (e) => {
        if (!e) return false;
        changePage.current = true;
        changeShow.current = true;
        const value = e?.value ?? false;
        setIsShowSelected(value);
    };

    const handleChange = (key, e) => {
        const value = e?.value ?? e?.target?.value ?? "";
        if(key === "RangePicker") {
            const DateForm = Array.isArray(value) ? value[0] : value;
            const DateTo = Array.isArray(value) ? value[1] : value;
            setRangeDate([DateForm, DateTo]);
        } else {
            setFilterGrid({...filterGrid, [key]: value});
        }
    };

    const onChangePage = (page) => {
        changePage.current = true;
        filter.current.skip = page * (filter.current?.limit ?? 20);
        loadData();
    };

    const onChangePerPage = (per) => {
        changePage.current = true;
        filter.current.skip = 0;
        filter.current.limit = per;
        loadData();
    };

    const onSelectionChanged = (e) => {
        let tmpSelectedRowKeys = [...selectedRowKeys];
        let tmpSelectedRowDatas = [...(selectedRowDatas.current ?? [])];

        if (e?.currentDeselectedRowKeys?.length > 0) {
            if (changePage.current && !isShowSelected) {
                changePage.current = false;
            } else {
                e.currentDeselectedRowKeys.forEach((key) => {
                    tmpSelectedRowKeys = tmpSelectedRowKeys.filter((i) => i !== key);
                    tmpSelectedRowDatas = tmpSelectedRowDatas.filter((i) => i.TransID !== key);
                });
            }
        }

        if (e?.currentSelectedRowKeys?.length > 0) {
            e.selectedRowsData.forEach((data) => {
                const idxKey = tmpSelectedRowKeys.indexOf(data?.TransID ?? '');
                const idxData = tmpSelectedRowDatas.findIndex((i) => i.TransID === (data?.TransID ?? ''));
                if (idxKey === -1) {
                    tmpSelectedRowKeys.push(data?.TransID ?? '');
                }
                if (idxData === -1) {
                    tmpSelectedRowDatas.push(data);
                }
            });
            changePage.current = false;
        }

        setSelectedRowKeys(tmpSelectedRowKeys);
        selectedRowDatas.current = tmpSelectedRowDatas;
    };

    const onContentReady = (e) => {
        const sltRowKeys = e?.component?.getSelectedRowKeys();
        if (changeShow.current && JSON.stringify(sltRowKeys) === JSON.stringify(selectedRowKeys)) {
            setTimeout(() => {
                changePage.current = false;
                changeShow.current = false;
            }, 50);
        }
    };

    const saveApproval = (mode) => {
        if (mode === undefined) return false;
        if (selectedRowDatas.current?.length > 0) {
            Config.popup.show('yesno', Config.lang(`Ban_co_chac_chan_muon_${mode ? 'tu_choi' : 'duyet'}`), () => _saveApproval(mode));
        } else {
            Config.popup.show('warning', Config.lang('Ban_chua_chon_nhan_vien'));
        }
    };

    const _saveApproval = (mode) => {
        if (mode === undefined) return false;
        selectedRowDatas.current = selectedRowDatas.current.map((item) => {
            item.IsUsed = 1;
            return item;
        });
        const params = {
            FormID: "W29F2038",
            Mode: mode,
            Data: JSON.stringify(selectedRowDatas.current ?? []),
        };
        setLoading(true);
        dispatch(
            W29F2038Actions.saveApproval(params, (err, data) => {
                setLoading(false);
                if (err) {
                    return Config.popup.show('ERROR', err);
                }
                if (data?.Status === 0) {
                    Config.notify.show('success', Config.lang(`${mode ? 'Tu_choi' : 'Duyet'}_thanh_cong`), 2000);

                    // Bỏ chọn Checkbox trên lưới
                    setSelectedRowKeys([]);
                    selectedRowDatas.current=null;

                    // load lại lưới
                    loadData();

                } else if (data?.Status === 1) {
                    Config.notify.show('error', Config.lang(`${mode ? 'Tu_choi' : 'Duyet'}_that_bai`), 2000);
                }
            })
        );
    };

    //true: Con key chua duoc select; false: da select het..
    const _checkSelectedRowsKeys = (dataSource) => {
        let isCheck = false;
        if (dataSource && dataSource.length > 0 && selectedRowDatas?.current?.length > 0) {
            const dataKeys = dataSource.map(d => d.TransID);
            for (let key of selectedRowDatas.current) {
                if (!dataKeys.includes(key)) {
                    isCheck = true;
                    break;
                }
            }
        }
        return isCheck;
    };

    let _dataGrid = dataGrid.rows;
    let _selectedRowKeys = selectedRowKeys;
    if (isShowSelected) {
        tmpCurrentSelectedRowKeys.current = selectedRowKeys;
        if (selectedRowDatas.current) {
            _dataGrid = selectedRowDatas.current;
            if (_selectedRowKeys.length === tmpCurrentSelectedRowKeys.current.length) changeShow.current = false;
            _selectedRowKeys = tmpCurrentSelectedRowKeys.current;
        }
    } else if (changeShow.current) {
        if (!_checkSelectedRowsKeys(_dataGrid)) changeShow.current = false;
    }

    return (
        <>
            <LoadPanel
                shadingColor={'rgba(0,0,0,0.4)'}
                position={{ my: 'center', of: '#W29F2038-Popup' }}
                visible={loading}
                showIndicatorOrgchartID
                shadingOrgchartID
                showPaneOrgchartID
            />
            <Modal id={"W29F2038-Popup"} zIndex={1020} open={open} width={"80%"}>
                <ModalHeader showClose={false}>
                    <ActionToolbar
                        alignment={'flex-end'}
                        title={Config.lang('Duyet_bo_sung_thoi_gian_vao_ra_hang_loat')}
                        showBorder={false}
                        upperCase={false}
                        className={`${classes.actionToolbar}`}
                    >
                        <Button
                            className={"mgr5"}
                            color={'primary'}
                            viewType={'outlined'}
                            text={Config.lang('Dong1')}
                            onClick={() => closePopup(true)}
                        />
                        <Button
                            className={"mgr5"}
                            color={'primary'}
                            viewType={'filled'}
                            text={Config.lang('Duyet')}
                            startIcon={'Approval'}
                            onClick={() => saveApproval(0)}
                        />
                        <Button
                            className={"mgr5"}
                            color={'danger'}
                            viewType={'filled'}
                            text={Config.lang('Tu_choi')}
                            startIcon={'Cancel'}
                            onClick={() => saveApproval(1)}
                        />
                    </ActionToolbar>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} sm={12} md={5} lg={4} className={`${classes.panel} ${classes.leftCol}`}>
                            <Row>
                                <ActionToolbar
                                    alignment={'space-between'}
                                    showBorder={false}
                                    upperCase={false}
                                    className={`${classes.actionToolbarFilter}`}
                                >
                                    <div className={`${classes.flex} ${classes.alignCenter} ${classes.justifyCenter} ${classes.gap10}`}>
                                        <ButtonIcon name={'Filter'} circular size={"medium"} viewType={"text"} onClick={() => collapsePanel(false)}/>
                                        {!minimum && <Typography>{Config.lang('Tieu_chi_loc')}</Typography>}
                                    </div>
                                    {!minimum && (
                                        <ButtonIcon name={'ArrowLeft'} circular size={"medium"} viewType={"text"} onClick={() => collapsePanel(true)}/>
                                    )}
                                </ActionToolbar>
                            </Row>
                            <div className={`${classes.panelForm}`}>
                                <div className={`${classes.mgb15}`}>
                                    <div className={`${classes.flex} ${classes.alignCenter} ${classes.justifyBetween}`}>
                                        <Checkbox
                                            checked={isShowSelected}
                                            label={Config.lang('Hien_thi_du_lieu_da_chon')}
                                            color={'primary'}
                                            onChange={changeTypeShow}
                                        />
                                        <Typography type={'p1'}>{`(${selectedRowKeys?.length ?? 0})`}</Typography>
                                    </div>
                                </div>
                                <div className={`${classes.mgb15}`}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Label>{Config.lang('Nhan_vien')}</Label>
                                            <TextInput
                                                className={`${classes.mgb0}`}
                                                startIcon={'Search'}
                                                placeholder={Config.lang('Ma_nhan_vien_Ten_nhan_vien')}
                                                viewType={'outlined'}
                                                value={filterGrid.Employee ?? ''}
                                                onChange={(e) => handleChange('Employee', e)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`${classes.mgb15}`}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Label>{Config.lang('Co_cau_to_chuc')}</Label>
                                            <Dropdown
                                                multiple
                                                clearAble
                                                dataSource={dataOrgChart}
                                                valueExpr={"OrgChartID"}
                                                displayExpr={"OrgName"}
                                                keyExpr={'OrgName'}
                                                onChange={(e) => handleChange("OrgChartID", e)}
                                                value={filterGrid.OrgChartID}
                                                viewType={'outlined'}
                                                placeholder={Config.lang("Co_cau_to_chuc")}
                                                style={{margin: 0}}
                                            >
                                                <TreeView
                                                    multiple
                                                    allowSearch
                                                    dataSource={dataOrgChart}
                                                    displayExpr={"OrgName"}
                                                    id={"OrgChartID"}
                                                    onChange={(e) => handleChange("OrgChartID", e)}
                                                    parentID={"OrgChartParentID"}
                                                    value={filterGrid.OrgChartID}
                                                    valueExpr={"OrgChartID"}
                                                />
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`${classes.mgb15}`}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <DateRangePicker
                                                controls
                                                clearAble
                                                value={rangeDate}
                                                viewType={"outlined"}
                                                label={Config.lang("Ngay_cong")}
                                                onChange={e => handleChange("RangePicker", e)}
                                                placeholder={"DD/MM/YY - DD/MM/YY"}
                                                returnFormat={"YYYY-MM-DD"}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`${classes.flex} ${classes.alignCenter} ${classes.justifyEnd}`}>
                                    <Button
                                        color={'primary'}
                                        viewType={'outlined'}
                                        text={Config.lang('Tim_kiem')}
                                        startIcon={'Search'}
                                        onClick={search}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={7} lg={8} className={`${classes.panel} ${classes.rightCol}`}>
                            <GridContainer
                                reference={ref => gridRef.current = ref}
                                totalItems={dataGrid?.total ?? 0}
                                itemPerPage={filter.current?.limit}
                                skipPerPage={filter.current?.skip}
                                listPerPage={[20, 40, 60, 80]}
                                dataSource={_dataGrid ?? []}
                                disabled={loading}
                                keyExpr={'TransID'}
                                gridProps={{
                                    style: {
                                        minHeight: 400,
                                    },
                                }}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth
                                typeShort={window.innerWidth < 768}
                                loadPanel={{
                                    enabled: loading,
                                }}
                                height={'calc(100vh - 180px)'}
                                selection={{
                                    allowSelectAll: true,
                                    mode: 'multiple',
                                    selectAllMode: 'allPages',
                                    showCheckBoxesMode: 'always',
                                }}
                                allowColumnResizing
                                typePaging={'remote'}
                                selectedRowKey={_selectedRowKeys}
                                onChangePage={onChangePage}
                                onChangePerPage={onChangePerPage}
                                onSelectionChanged={onSelectionChanged}
                                onContentReady={onContentReady}
                            >
                                <Column
                                    dataField={'TransID'}
                                    visible={false}
                                />
                                <Column
                                    caption={Config.lang('Ma_nhan_vien')}
                                    dataField={'EmployeeID'}
                                    alignment={'left'}
                                    fixed
                                    allowFixing
                                    width={150}
                                />
                                <Column caption={Config.lang('Ten_nhan_vien')} dataField={'EmployeeName'} fixed allowFixing />
                                <Column caption={Config.lang('Ngay_cong')} dataField={'AttendanceDate'} dataType={'date'} format={'dd/MM/yyyy'} />
                                <Column caption={`${Config.lang('Vao_ra')} 1`} dataField={'InOut1'} />
                                <Column caption={`${Config.lang('Vao_ra')} 2`} dataField={'InOut2'} />
                                <Column caption={`${Config.lang('Vao_ra')} 3`} dataField={'InOut3'} />
                                <Column caption={`${Config.lang('Vao_ra')} 4`} dataField={'InOut4'} />
                                <Column caption={`${Config.lang('Vao_ra')} 5`} dataField={'InOut5'} />
                            </GridContainer>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

W29F2038.propTypes = {
    open: PropTypes.bool,
    closePopup: PropTypes.func,
};

W29F2038.defaultProps = {
    open: false,
    closePopup: null,
};

export default W29F2038;
