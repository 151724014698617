/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 11/6/2020
 * @Example
 */
import React, { Component } from "react";

import { connect } from "react-redux";
import Config from "../../../../config";
import * as W29F2030Actions from "../../../../redux/W2X/W29F2030/W29F2030_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import moment                                       from "moment";
import { browserHistory }                           from "react-router";
import { Column }                                   from "devextreme-react/data-grid";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridContainer from "../../../grid-container/grid-container";
import Filter from "../../../filter/filter";
import W29F2030Popup from "../W29F2030/W29F2030Popup";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import {
    Button,
    ButtonIcon,
    Row,
    Col,
    Label,
    DatePicker,
    Dropdown,
    Modal,
    ModalHeader,
    Typography,
} from "diginet-core-ui/components";
import W84F3005             from "../../../W8X/W84/W84F3005/W84F3005";
import { Calendar } from 'diginet-core-ui/icons';
import UserImage    from "../../../common/user/user-image";

class W29F2030 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "",
            rowData: null,
            gridLoading: false,
            loading: false,
            showW29F2030Popup: false,
            AttendanceDate: null,
            dataGrid: [],
            total: 0,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            loadingCboEmployees: false,
            showW84F3005: false,
            Employee: "",
            AppStatusID: "",
            ShiftID: "",
        };
        this.filter = {
            skip: 0,
            limit: 10
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W29F2030", isPer => {
            this.setState({ iPermission: isPer });
        });
    };

    loadDataGrid = () => {
        const {location} = this.props;
        const dataType = location && location.state && Object.entries(location.state).reduce((a, [key, value]) => {
            a[key.toLowerCase()] = value;
            return a;
        }, {});
        const type = dataType?.type || 0;
        const {  skip, limit } = this.filter;
        const { Datefrom, Dateto, Employee, AppStatusID, ShiftID } = this.state;
        const param = {
            FormID: "W29F2030",
            Type: Number(type),
            Language: Config.language || 84,
            EmployeeID: Employee ? Employee : "",
            Datefrom: Datefrom || null,
            Dateto: Dateto || null,
            Status: AppStatusID || AppStatusID === 0 ? AppStatusID : "",
            ShiftID: ShiftID || "",
            skip: skip,
            limit: limit
        };
        this.setState({ gridLoading: true });
        this.props.w29f2030Actions.getDataGrid(param, (error,data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data) {
                this.setState({
                    dataGrid: data && data.rows ? data.rows : [],
                    total: data && data.total ? data.total : 0,
                }, () => {
                    this.setState({ gridLoading: false });
                });
            }

        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W29F2030",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({loading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W29F2030",
            Language: Config.language || 84
        };
        this.setState({loading: true});
        this.props.generalActions.getCboAppStatus(param, error => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

     loadCboShift = () => {
         this.setState({loading: true});
        this.props.w29f2030Actions.getListOfShift(error => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
        };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID }
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onCloseModal = (isSave = false) => {
        if(isSave) {
            this.loadDataGrid();
        }
        this.setState({
            showW29F2030Popup: false
        });
    };

    onView = e => {
        const { data } = e.row;
        this.setState({
            mode: "edit",
            rowData: data,
            showW29F2030Popup: true
        });
    };

    filterChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "Datefrom":
                this.setState({
                    Datefrom: e.value
                });
                break;
            case "Dateto":
                this.setState({Dateto: e.value });
                break;
            case "AppStatusID":
                this.setState({AppStatusID: e.value });
                break;
            case "EmployeeID":
                this.setState({Employee: e.value });
                break;
            case "ShiftID":
                this.setState({ShiftID: e.value });
                break;
            default:
                break;
        }
    };

    renderEmpProfile = e => {
        const {data = {}} = e;
        const date = moment.utc(data.CreateDate).format("LT, DD/MM/YYYY");
        const user = Config.getUser({EmployeeID: data.EmployeeID});
        if (data) {
            if (data.UserPictureURL) {
                data.UserPictureURL = data.UserPictureURL.indexOf('http') < 0 ? Config.getCDNPath() + data.UserPictureURL : data.UserPictureURL;
            } else if (data.EmployeeID) {
                data.UserPictureURL = user && user.UserPictureURL ? user.UserPictureURL : ""
            }
        }
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div>
                    <UserImage width={64} height={64} data={data} onClick={() => this.onChangePageProfile(data)}/>
                </div>
                <div>
                    <Typography type={"h3"}><UserName data={data}/></Typography>
                    <Typography>{data.DepartmentName || ""}</Typography>
                    <div className={"display_row align-center"}>
                        <Calendar width={12} heigth={14}/>
                        <div className={"pdl2x"}>
                            <Typography type={"p2"}>{date || ""}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const {data = {}} = e;
        const date = moment.utc(data.AttendanceDate).format("DD/MM/YYYY");
        return (
            <div className={"pdt10 pdb10 "}>
                {data.AttendanceDate && (
                    <Typography type={"p2"}>
                        {Config.lang("Ngay")}: {date || ""} {data.ShiftName || ""}
                    </Typography>
                )}
                {data.NoTimeOn && (
                    <Typography type={"p2"}>
                         {data.NoTimeOn || ""}
                    </Typography>
                )}
                {data.NoTimeOff && (
                    <Typography type={"p2"}>
                         {data.NoTimeOff || ""}
                    </Typography>
                )}
                {data.ApproverName && (
                    <Typography type={"p2"}>
                        {Config.lang("Nguoi_duyet")}: {data.ApproverName || ""}
                    </Typography>
                )}
                {data.Notes && (
                    <Typography type={"p2"}>
                        {Config.lang("Ghi_chu")}: {data.Notes || ""}
                    </Typography>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const {data} = e;
        return <Status data={data}  keyExpr={"Status"} displayExpr={"StatusName"}/>;
    };

    onViewHistory = (e) => {
        this.setState({ showW84F3005: true, rowData: e.data });
    }

    onDelete = (e) => {
        const { data } = e.row;
        const params = {
            FormID: "W29F2030",
            TransID: data?.TransID || "",
            AttendanceDate: data?.AttendanceDate || null,
            EmployeeID: data?.EmployeeID || ""
        };

        Config.popup.show('YES_NO', Config.lang("Ban_co_muon_xoa_phieu_hay_khong"),
            () => {
                this.props.w29f2030Actions.deletes(params, (errors, data) => {
                    if (errors) {
                        Config.popup.show('ERROR', errors);
                        return false;
                    }
                    if (data && data.Status === 0) {
                        Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                        this.loadDataGrid();
                    }
                });
            });

    };

    renderAction = e => {
        const { iPermission } = this.state;
        const { data } = e.row;
        const { location } = this.props;
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    circular
                    viewType={'text'}
                    name={'History'}
                    className={'mgr5'}
                    onClick={() => this.onViewHistory(e)}
                />
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"View"}
                    disabled={!iPermission > 0}
                    onClick={() => this.onView(e)}
                />

                {location?.state?.menu?.Type !== "ESS" && <ButtonIcon
                    circular
                    viewBox
                    viewType={"text"}
                    name={"delete"}
                    disabled={iPermission <= 3 || (data.Status  !== 0 && data.Status !== 1)}
                    onClick={() => this.onDelete(e)}
                />}
            </div>
        );
    };

    renderFilter = () => {
        const { getCboAppStatus, getListOfShift } = this.props;
        const { dataCboEmployees, loading, Datefrom, Dateto,  AppStatusID, Employee, ShiftID } = this.state;
        return (
            <Filter
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                isUseDDCore
                renderFilter={() => {
                    return (
                        <>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Label className={"mgr5"} style={{minWidth: 30}}>{Config.lang("Tu")}</Label>
                                            <DatePicker
                                                placeholder={"DD/MM/YYYY"}
                                                max={Dateto}
                                                clearAble
                                                viewType={"outlined"}
                                                displayFormat={"DD/MM/YYYY"}
                                                value={Datefrom}
                                                onChange={e => this.filterChange("Datefrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Label className={"mgr5"} style={{minWidth: 30}}>{Config.lang("Den")}</Label>
                                            <DatePicker
                                                placeholder={"DD/MM/YYYY"}
                                                min={Datefrom}
                                                clearAble
                                                viewType={"outlined"}
                                                displayFormat={"DD/MM/YYYY"}
                                                value={Dateto}
                                                onChange={e => this.filterChange("Dateto", e)}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            viewType={"outlined"}
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onChange={e => this.filterChange("AppStatusID", e)}
                                            clearAble={true}
                                            value={AppStatusID === "%" ? "" : AppStatusID}
                                        />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            viewType={"outlined"}
                                            label={Config.lang("Ca_lam_viec")}
                                            dataSource={getListOfShift}
                                            displayExpr={"ShiftName"}
                                            valueExpr={"ShiftID"}
                                            onChange={e => this.filterChange("ShiftID", e)}
                                            clearAble={true}
                                            value={ShiftID}
                                        />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataCboEmployees?.rows || []}
                                            total={dataCboEmployees?.total ?? 0}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            value={Employee === "%" ? null : Employee}
                                            loading={loading.cboEmployees}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            renderSelectedItem={"EmployeeName"}
                                            searchDelayTime={1000}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            onChange={e => this.filterChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            <div className={"mgt4x"} style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadDataGrid();
        this.loadCboAppStatus();
        this.loadCboEmployees();
        this.loadCboShift();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const TransID1 = this.getInfo(nextProps).TransID;
        if (TransID1 && this.TransID !== TransID1) {
            this.TransID = TransID1;
            this.checkNotify(TransID1);
        }
    };

    checkNotify = (TransID) => {
        if (TransID) {
            this.onView({ row: { data: { TransID: TransID } } });
            browserHistory.push(Config.getRootPath() + 'W29F2030');
            this.TransID = null;
        }
    };

    getInfo = (props) => {
        if(!props) return {};
        const {location} = props ? props : {};
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('voucher_id')) {
            return { TransID: url.get('voucher_id') };
        } else if (location && location.state) {
            return {
                TransID: location.state.voucher_id,
            }
        } else {
            return {};
        }
    };

    render() {
        const { gridLoading, iPermission, showW29F2030Popup, rowData,
                  mode, dataGrid, total, showW84F3005 } = this.state;
        const { skip, limit } = this.filter;
        if (iPermission <= 0) return null;

        return (
            <>
                {showW84F3005 && <W84F3005 open={showW84F3005} onClose={() => this.setState({ showW84F3005: false })} FormID="W29F2030" TransID={rowData?.TransID  ?? ''} />}
                <Modal
                    open={showW29F2030Popup}
                    onClose={() => this.onCloseModal(false)}
                >
                    <ModalHeader>{Config.lang("Bo_sung_gio_vao_ra")}</ModalHeader>
                    <W29F2030Popup mode={mode} data={rowData} onClose={this.onCloseModal} />
                </Modal>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("Bo_sung_gio_vao_ra")}/>
                <GridContainer
                    keyExpr={"TransID"}
                    style={{ border: "none" }}
                    dataSource={dataGrid}
                    totalItems={total}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("No_data")}
                    hoverStateEnabled={true}
                    loading={gridLoading}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column width={300} cellRender={this.renderEmpProfile} />
                    <Column cellRender={this.renderInfo} />
                    <Column cellRender={this.renderStatus} alignment={"center"} width={200} />
                    <Column cellRender={this.renderAction} width={200} />
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.general.getCboAppStatus,
            getListOfShift: state.W29F2030.getListOfShift,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w29f2030Actions: bindActionCreators(W29F2030Actions, dispatch)
        })
    )
)(W29F2030);
